// Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted.

import axios from 'axios'
import Qs from 'qs'
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import store from './store'
import router from './router'

const a = axios.create({
  baseURL: store.state.apiUrl,
  paramsSerializer: function (params) {
    return Qs.stringify(params, {arrayFormat: 'repeat'})
  },
})

a.interceptors.request.use(function (config) {
  if (store.state.auth.accessToken) {
    config.headers['Authorization'] = `Bearer ${store.state.auth.accessToken}`
  }
  if (store.state.impersonation && store.state.impersonation.active) {
    config.headers['Impersonate'] = store.state.user.username
  }
  return config
}, function (error) {
  return Promise.reject(error)
})
a.interceptors.response.use(function (response) {
  return response
}, function (error) {
  error.backendErrors = {
    nonFieldErrors: [],
    fieldErrors: {},
  }
  if (!error.response) {
    error.backendErrors.nonFieldErrors.push("CORS error: you are not allowed to make this request")
  } else if (error.response.status === 404) {
    error.backendErrors.nonFieldErrors.push('Resource not found')
  } else if (error.response.status === 403) {
    error.backendErrors.nonFieldErrors.push('Permission denied')
  } else if (error.response.status === 500) {
    error.backendErrors.nonFieldErrors.push('A server error occured')
  } else if (error.response.data) {
    if (error.response.data.detail) {
      error.backendErrors.nonFieldErrors.push(error.response.data.detail)
    } else {
      if (error.response.data.non_field_errors) {
        error.backendErrors.nonFieldErrors = error.response.data.non_field_errors
      }
      error.backendErrors.fieldErrors = error.response.data
    }
  }
  if (error.backendErrors.length === 0) {
    error.backendErrors.nonFieldErrors.push('An unknown error occured, ensure your are connected to the internet and your funkwhale instance is up and running')
  }
  return Promise.reject(error)
})
const refreshAuthLogic = async (failedRequest) => {

  try {
    let response = await a.post('refresh-token', {'refresh_token': store.state.auth.refreshToken})
    store.dispatch('handleLogin', response.data)
    failedRequest.response.config.headers['Authorization'] = `Bearer ${response.data.access_token}`
    return Promise.resolve()
  } catch (e) {
    store.commit('auth', {})
    store.commit('authenticated', false)
    router.push({name: 'login', query: {next: router.currentRoute.fullPath}})
  }
  
}

createAuthRefreshInterceptor(a, refreshAuthLogic);

export default a